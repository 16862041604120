.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.wrapper {
  width: 768px;
  margin: 0 auto;
}

canvas {
  width: 100%;
}

.input {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 10px auto 0;
}

.input input {
  width: 75%;
  height: 2em;
}

.input button {
  width: 20%;
  color: #fff;
  background: #252743;
  border-radius: 4px;
  border: none;
  pointer-events: all;
}

.downloads {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 20px auto 0;
}

.downloads button {
  width: 48%;
  height: 2.5em;
  color: #fff;
  background: #4d5075;
  border-radius: 4px;
  border: none;
  pointer-events: all;
}

button:active {
  opacity: 0.5;
}

.list {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
}

.list div:first-child {
  padding-bottom: 20px;
  font-size: 1.4em;
}

.list div+div {
  padding: 20px 0;
  border-top: 1px solid rgba(37, 39, 67, 0.2);
}

.list div:nth-of-type(even) {
  text-align: right;
}

.list div:nth-of-type(odd) {
  font-weight: bold;
}

.uipanel {
  position: relative;
  margin-top: -10px;
  padding: 20px 0 20px;
  background: #fff;
  border-radius: 10px;
  z-index: 2;
}

.readme {
  padding: 0 20px;
  color: #fff;
  letter-spacing: 0.01em;
  list-style: none;
}

.readme li {
  position: relative;
  padding: 0 0 0 1em;
}

.readme li:before {
  content: "・";
  position: absolute;
  top: 0;
  left: 0;
}

.copy {
  padding: 0 20px;
  color: #fff;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 768px) {
  .wrapper {
    width: 95%;
    margin: 0 auto;
  }
}